/*=============================
    Additions to Bootstrap
===============================*/

.back-btn {
    position: fixed;
    z-index: 10;
    border-radius: 25px;
    width: 90px;
    height: 50px;
    background: white;
    left: 15px;
    top: 15px;
    box-shadow: 
        0 2px 2px 0 rgba(0,0,0,.14),
        0 1px 5px 0 rgba(0,0,0,.12),
        0 3px 1px -2px rgba(0,0,0,.2);
}

.back-btn > div {
    position: relative;
    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    // align: center;
    width: 100%;
    height: 100%;
    color: black;
}

.back-btn > div > span {
    // flex: 1;
    margin-right: 10px;
    // line-height: ;
    
}

.back-chevron {
    height: 27px;
    width: 27px;
    // margin-left: 2px;
}

